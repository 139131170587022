import { Row, Col} from "antd";
import { Link } from "react-router-dom";

const Visits = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <h6>
            <span style={{ color: "#000000", fontSize: "20px", background: "white", padding: "5px", fontFamily: "Quicksand" }} className='mb-2 fw-bold'>
              {" "}
              TRENDS
            </span>
          </h6>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <Link
            className='btn'
            style={{ background: "#A37BF7", color: "white", width: "300px", borderRadius: "10px", minHeight: "50px", lineHeight: "2rem", fontFamily: "Quicksand", fontWeight: "600" }}
            size='large'
            width={150}
            to={{
              pathname: "/traffic-trends",
              state: {
                activeTab: 1,
              },
            }}
          >
            Visits
          </Link>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <Link
            className='btn'
            style={{ background: "#8DD0DE", color: "white", width: "300px", borderRadius: "10px", minHeight: "50px", lineHeight: "2rem", fontFamily: "Quicksand", fontWeight: "600" }}
            size='large'
            width={150}
            to={{
              pathname: "/traffic-trends",
              state: {
                activeTab: 2,
              },
            }}
          >
            Shares
          </Link>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <Link
            className='btn'
            style={{ background: "#F76870", color: "white", width: "300px", borderRadius: "10px", minHeight: "50px", lineHeight: "2rem" }}
            size='large'
            width={150}
            to={{
              pathname: "/traffic-trends",
              state: {
                activeTab: 3,
              },
            }}
          >
            Importance
          </Link>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <h6>
            <span style={{ color: "#000000", fontSize: "20px", background: "white", padding: "5px", fontFamily: "Quicksand" }} className='mb-2 fw-bold'>
              {" "}
              SNAPSHOT
            </span>
          </h6>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col span={24} offset={9}>
          <Link
            className='btn'
            style={{ background: "#4FE7B1", color: "white", width: "300px", borderRadius: "10px", minHeight: "50px", lineHeight: "2rem", fontFamily: "Quicksand", fontWeight: "600" }}
            size='large'
            width={150}
            to={{
              pathname: "/traffic-snapshot",
              state: {
                activeTab: 1,
              },
            }}
          >
            Visit & Shares
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Visits;
