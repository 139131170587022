import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Layout, Button, Form, Input, Image, message, Space } from "antd";
import { useHistory } from "react-router-dom";
import loginImage from "../assets/images/loginpage.png";
import backgroundImageLogin from "../assets/images/backgroundlogin.png";
import { ApifordataPost } from "../pages/Helper/ApiForGetData";
const ResetPassword = (props) => {
  const [forgetPasswordFlag, setForgetpasswordFlag] = useState(true);
  const [changePassword, setchangePassword] = useState(true);
  const [localemail, setLocalemail] = useState(null);

  const history = useHistory();

  const handleForgetPasswordChange = async (values) => {
    let payLoad = {
      email: localemail,
      new_password: values.newpassword,
      verify_password: values.confirmpassword,
    };
    let response = await ApifordataPost("/password/forgot-password/", payLoad);
    if (response.success == false) {
      message.error(response.message);
      setForgetpasswordFlag(false);
      return;
    } else {
      message.success(response?.message);

      setForgetpasswordFlag(false);
      setchangePassword(false);
      history.push("/");
    }
  };
  useEffect(() => {
    let temp = window.location.href.split("email=");

    setLocalemail(temp[1]);
  });

  return (
    <>
      <Layout
        style={{
          background: "#274361",
          minHeight: "100vh",
          overflow: "auto",
          backgroundImage: `url(${backgroundImageLogin})`,
          backgroundSize: "100vw",
          backgroundRepeat: "no-repeat",
        }}
        className='App'
      >
        <section className='vh-100'>
          <div className='container py-5 h-100'>
            <div className='row d-flex align-items-center justify-content-center h-100'>
              <div className='col-md-7 col-lg-5 col-xl-5 offset-xl-1'>
                <div className='m-5 p-2'>
                  <div className='text-white mb-4 '>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "45px",
                      }}
                    >
                      Reset Password
                    </span>
                  </div>
                  <div className='text-white mb-4 mt-4'>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    ></span>
                  </div>
                  <div className='mt-4'>
                    {changePassword && forgetPasswordFlag && (
                      <Form
                        name='basic'
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{
                          maxWidth: "100%",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete='off'
                        className='ml-2'
                        onFinish={handleForgetPasswordChange}
                      >
                        <Form.Item
                          name='newpassword'
                          rules={[
                            {
                              required: true,

                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder='New Password' size='large' style={{ background: "white" }} prefix={<EyeOutlined />} />
                        </Form.Item>
                        <Form.Item
                          name='confirmpassword'
                          rules={[
                            {
                              required: true,

                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder='Verify Password' size='large' style={{ background: "white" }} prefix={<EyeOutlined />} />
                        </Form.Item>

                        <Space>
                          <Button
                            type='primary'
                            htmlType='submit'
                            block
                            style={{
                              background: "#1DB9DE",
                              borderRadius: "10px",
                              color: "white",
                            }}
                            size='large'
                            className='m-1'
                          >
                            Submit
                          </Button>

                          <Button
                            type='primary'
                            block
                            style={{
                              background: "#1DB9DE",
                              borderRadius: "10px",
                              color: "white",
                            }}
                            size='large'
                            onClick={() => {
                              setForgetpasswordFlag(false);
                              setchangePassword(false);
                            }}
                            className='m-1'
                          >
                            Cancel
                          </Button>
                        </Space>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-lg-7 col-xl-6'>
                <Image src={loginImage} className='img-fluid' alt='Phone image' preview={false} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default ResetPassword;
