import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";
const OpeningBar = (props) => {
  const { calApi } = props;
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    let temData = [];
    let tempCategory = [];

    if (props?.openingData?.data) {
      props.openingData.data.forEach((e) => {
        if (e && e.BrandName) {
          temData.push(e.NewOpenings);
          tempCategory.push(e.BrandName);
        }
      });
    }

    setCategories(tempCategory);
    setData1(temData);
    setTimeout(()=>{
      setLoading(false);
    },3000)

    
  }, [props, calApi]);

  const options = {
    chart: {
      height: 650,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
        export: {
          png: {
            filename: "Meaningful Vision",
          },
        },
      },
      
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        columnWidth: categories.length > 10 ? "80%" : "20%",
        dataLabels: {
          position: "top",
        },
      },
    },
    // colors: ["#1db9de", "#2ec551", "#f39c12", "#e74c3c"],
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: categories,
      position: "bottom",
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (val) => val,
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin></Spin>
        </div>
      )}
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Number of openings",
            data: data1,
          },
        ]}
        type='bar'
        height={650}
      />
    </div>
  );
};

export default OpeningBar;
