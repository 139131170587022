import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Input, Row, Col, Button, Space, message } from "antd";
import { URL } from "../Helper/MainURL";

const CreateUserModal = (props) => {
  const { openModal, handleCloseModal ,dropdownValues,initialState} = props;
  const {competitive_set,country } =dropdownValues
 
  const [selectedCount,setselectedCount]=useState([])
  const onFinish = async(values) => {
    
    
    let payload={
        organization:values.Organization,
        chains:values.Chains.toString(),
        country:values.Country,
        dashboards:values.dashboard.toString()

    }
    try{
        await fetch(`${URL}/organization/${initialState?.length==0?`add`:`edit`}`, {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          })
            .then(function (response) {
              response.json().then(function (data) {
            message.success(data?.message);
            handleCloseModal()
              });
            })
            .catch((error) => {
             
              console.error("Fetch error:", error);
            }); 
        
    }
    catch(err)
    {
        
    }
  };
  
useEffect(()=>{
  setselectedCount([])
if(props?.initialState?.Chains?.length>0)
  {
var tempArray=new Array();
tempArray=initialState.Chains
 tempArray=new Array();

  }
 
},[props])
  return (
    <Modal title={initialState?.length==0?'Create Organization':"Edit Organization"} open={openModal} onCancel={handleCloseModal} layout='vertical' autoComplete='off' footer={false} width={330} maskClosable={false}  destroyOnClose={true} >
      <Form onFinish={onFinish}   initialValues={{
      ...initialState
      }}
>
        <Row>
          <Col span={12}>
            <Form.Item
              label={"Organization"}
              name='Organization'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Please enter organization" },
                
              ]}
            >
              <Input
                placeholder='Organization'
                style={{
                  width: 250,
                }}
                size='large'
                disabled={initialState?.length==0?false:true}
              />
              
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
         
            <Form.Item
             label={
              <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                {" "}
                Chain{" "}
                <span className={selectedCount && selectedCount.length>0 ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                  {selectedCount && selectedCount.length>0?selectedCount.length:"" }
                </span>
              </span>
            }
             
              name='Chains'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Please select Chain" },
                
              ]}
            >
              <Select
                mode='multiple'
                className='shadow-sm dont-show'
                style={{
                  width: 250,
                }}
                allowClear
                placeholder='Click to select'
                size='large'
                options={competitive_set}
                onChange={(e)=>{
                 
                  setselectedCount(e)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
       
        <Row>
          <Col span={12}>
            <Form.Item
              label={"Country"}
              name='Country'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Please select Country" },
               
              ]}
            >
              <Select
               
                style={{
                  width: 250,
                }}
                allowClear
                placeholder='Click to select'
                size='large'
                options={country}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={"Sections"}
              name='dashboard'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Please enter organization" },
                
              ]}
            >
              <Select
               mode="multiple"
               style={{
                 width: 250,
               }}
               allowClear
               placeholder='Click to select'
               size='large'
               options={[{value:"Products",label:"Products"},{value:"Promotions",label:"Promotions"},{value:"Prices",label:"Prices"},{value:"Visits",label:"Visits"},
                {value:"Openings",label:"Openings"}
              ]}
             />
              
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ alignItems: "center" }}
            >
              <Space>
                <Button htmlType="submit">Save</Button>
               {
                initialState?.length==0  && <Button>Clear</Button>
               } 
               
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CreateUserModal;
