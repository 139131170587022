import React, { useEffect, useState } from "react";
import { Button, message, Input, Form } from "antd";
import { URL } from "../Helper/MainURL";

function UserProfile(props) {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let payload = {
      email: localStorage.getItem("email"),
    };
    fetch(`${URL}/get-user/user_detail`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setUserData(data.data[0]);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const onFinish = (values) => {
    if (values.newpassword != values.verifypassword) {
      message.error("please enter correct password");
      return;
    }
    let payload = {
      email: localStorage.getItem("email"),
      old_password: values.currentPassword,
      new_password: values.newpassword,
      verify_password: values.verifypassword,
    };

    fetch(`${URL}/password/update-password/`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          message.success(data?.message);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  return (
    <>
      <div className='container-fluid'>
        <div className='row gutters'>
          <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
            <div className='card h-150 bg-light'>
              <div className='card-body p-4'>
                <div className='row gutters mb-2'>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <h6 className='mb-2   mb-4'>Personal Details</h6>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'>
                      <Input style={{ fontWeight: "500", color: "black" }} disabled type='text' className='form-control ' id='fullName' placeholder='First Name' value={userData.First_Name} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'>
                      <Input style={{ fontWeight: "500", color: "black" }} disabled type='text' className='form-control' id='fullName' placeholder='Last Name' value={userData.Last_Name} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'>
                      <Input style={{ fontWeight: "500", color: "black" }} disabled type='email' className='form-control' id='eMail' placeholder='Email' value={userData.Email} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'>
                      <Input style={{ fontWeight: "500", color: "black" }} disabled type='email' className='form-control' id='eMail' placeholder='Organisation' value={userData.Organization} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'>
                      <Input style={{ fontWeight: "500", color: "black" }} disabled type='text' className='form-control' id='phone' placeholder='Phone Number' value={userData.Phone_number} />
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                    <div className='form-group'></div>
                  </div>
                  <div className='col-9'></div>
                  <div className='col-3 mb-4'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
            <div className='card h-150 bg-light'>
              <div className='card-body p-4'>
                <div className='row gutters mb-2'>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <h6 className='mb-2   mb-4'>Change Password</h6>
                  </div>
                  <Form
                    name='basic'
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete='off'
                  >
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2'>
                      <div className='form-group'>
                        <Form.Item
                          name='currentPassword'
                          rules={[
                            {
                              required: true,
                              message: "Please enter current password!",
                            },
                          ]}
                        >
                          <Input type='text' className='form-control' placeholder='Current Password' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2'>
                      <div className='form-group'>
                        <Form.Item
                          name='newpassword'
                          rules={[
                            {
                              required: true,
                              message: "Please enter new password !",
                            },
                          ]}
                        >
                          <Input type='text' className='form-control' id='fullName' placeholder='New Password' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2'>
                      <div className='form-group'>
                        <Form.Item
                          name='verifypassword'
                          rules={[
                            {
                              required: true,
                              message: "Please enter new password!",
                            },
                          ]}
                        >
                          <Input type='text' className='form-control' id='eMail' placeholder='Verify Password' />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='col-9'></div>
                    <div className='col-3'>
                      <Button block className='mb-4 export-button1 text-white' size={"large"} htmlType='submit'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
