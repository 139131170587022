import React from "react";
import { Modal, Form, Select, Input, Row, Col, Button, Space, message } from "antd";
import { URL } from "../Helper/MainURL";

const options=[

  {
  "name": "Ireland",
  "dial_code": "+353",
  "code": "IE"
  },


  {
  "name": "United Kingdom",
  "dial_code": "+44",
  "code": "GB"
  },
  

  ]
  const options1=[];

  options.map((e)=>{
    options1.push({label:e.name,value:e.dial_code})
  })
const CreateOrganization = (props) => {
  const { openModal2, handleCloseModal2,initialState,brands } = props;
  
  const onFinish = async (values) => {

    let payload = {
      ...values,
    };
    try {
      await fetch(`${URL}/${initialState?.length==0?"create-user/":"edit-user/"}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        
        body: JSON.stringify(payload),
      })
        .then(function (response) {
          response.json().then(function (data) {
            message.success(data?.message);
            handleCloseModal2();
          });
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    } catch (err) {}
  };
  return (
    <Modal open={openModal2} title={initialState?.length==0?"Create User":"Edit User"} layout='vertical' autoComplete='off' footer={false} width={350} maskClosable={false} destroyOnClose={true} onCancel={handleCloseModal2}>
      <Form onFinish={onFinish} initialValues={{
     first_name:initialState.First_Name,
     last_name:initialState.Last_Name,
     email:initialState.User,
     organization:initialState.Organization,
     phone_number:initialState.Phone_number
     }}>
        <Row>
          <Col span={12}>
            <Form.Item
              label={"First Name"}
              name='first_name'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Please enter first name" },
               
              ]}
            >
              <Input
               
                style={{
                  width: 250,
                }}
                size='large'
                 disabled={initialState?.length==0?false:true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={"Last Name"} name='last_name' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please enter last name" }]}>
              <Input
                style={{
                  width: 250,
                }}
                size='large'
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={"Email"} name='email' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please enter email" }]}>
              <Input
                style={{
                  width: 250,
                }}
                size='large'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={"Country"} name='country_code' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: false, message: "Please select Country" }]}>
            
              <Select options={options1}  style={{
                  width: 250,
                }}
                size='large'/>
            </Form.Item>
          </Col>
        </Row>
      <Row>
          <Col span={12}>
            <Form.Item label={"Phone Number"} name='phone_number' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: false, message: "Please select Country" }]}>
              <Input
                style={{
                  width: 250,
                }}
                size='large'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={"Organization"} name='organization' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please enter organization" }]}>
              <Select
                style={{
                  width: 250,
                }}
                allowClear
                placeholder='Click to select'
                size='large'
                options={brands}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ alignItems: "center" }}>
              <Space>
                <Button htmlType='submit'>Save</Button>
                
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CreateOrganization;
