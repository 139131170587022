import {createSlice} from '@reduxjs/toolkit'
export const  customeTheme =createSlice({
    name:"darklightmode",
    initialState:{
        themeCustome:true
    },
    reducers:{
        showLight:(state)=>{
            state.themeCustome=true
        },
        showDark:(state)=>{
            state.themeCustome=false
        }
    }
});
export const {showLight,showDark}=customeTheme.actions;