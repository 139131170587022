import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { showLight } from "../../redux/cutomeTheme";

const LineChart = ({ data, months, activeTab, mode }) => {
  const [chartData, setChartData] = useState([]);
  const [options, setOptions] = useState({});
  useEffect(() => {
    const monthData = months.filter(e => e !== "BRANDNAME");
    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    
      return color;
    };

    const processedData = data.map(series => {
      if (!series.data || !Array.isArray(series.data)) {
        return null;
      }

      return {
        ...series,
        data: series.data.map(y => ({ y, markerSize: 0 })),
        color: generateRandomColor()
      };
    }).filter(series => series !== null);
    const chartOptions = {
      chart: {
        height: 1000,
        type: 'line',
        padding: {
          top: 0,
          right: 0,
          bottom: 120,
          left: 0
        },
        background: showLight ? '#fff' : '#333',
        events: {
          markerClick: (event, chartContext, { seriesIndex, dataPointIndex }) => {
            toggleMarkerSize(seriesIndex, dataPointIndex);
          }
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true
          },
          export: {
            png: {
              filename: "Meaningful Vision",
            }
          }
        },
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: monthData,
        labels: {
          offsetY: 0
        }
      },
      legend: {
        position: 'top',
        offsetY: 6
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            if (activeTab === 1 && mode === "No Comparison") {
              return value.toLocaleString();
            } else {
              return value.toLocaleString() + "%";
            }
          }
        }
      },
      tooltip: {
        shared: false,
        intersect: false,
        y: {
          formatter: (value) => {
            if (activeTab === 1 && mode === "No Comparison") {
              return value.toLocaleString();
            } else {
              return value.toLocaleString() + "%";
            }
          }
        }
      },
      colors: processedData.map(series => series.color) 
    };
   

    setChartData(processedData);
    setOptions(chartOptions);
  }, [data, months, activeTab, mode]);

  const toggleMarkerSize = (seriesIndex, dataPointIndex) => {
    setChartData(prevData => {
      return prevData.map((series, sIndex) => {
        if (sIndex === seriesIndex) {
          const newDataPoints = series.data.map((point, pIndex) => {
            if (pIndex === dataPointIndex) {
              const newSize = point.markerSize === 0 ? 6 : 0;
              return { ...point, markerSize: newSize };
            }
            return point;
          });
          return { ...series, data: newDataPoints };
        }
        return series;
      });
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const svgExportButton = document.querySelector('.apexcharts-menu-item.exportSVG');
      const csvExportButton = document.querySelector('.apexcharts-menu-item.exportCSV');
      if (svgExportButton) {
        svgExportButton.style.display = 'none';
      }
      if (csvExportButton) {
        csvExportButton.style.display = 'none';
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div id="chart">
      {data && (
        <ReactApexChart 
          options={options} 
          series={chartData.map(series => ({
            name: series?.name,
            data: series?.data.map(point => point.y)
          }))} 
          type="line" 
          height={550} 
        />
      )}
    </div>
  );
};

export default LineChart;
