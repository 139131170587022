import React from "react";
import { useHistory,useLocation } from "react-router-dom";

function ProtectedRoute(props) {
  const history = useHistory();
  const location=useLocation();
 
  if (localStorage.getItem("token")) {
    if(location.pathname=="/user-management" && localStorage.getItem("roles")==="User")
    {
      
      return history.push("/");
    }
    else{
      return <div>{props.children}</div>;
    }
    
  } else {
    return history.push("/");
  }
}

export default ProtectedRoute;
