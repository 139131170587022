import { createSlice } from "@reduxjs/toolkit";

export const filterModal=createSlice({
    name:"filtermodal",
    initialState:{
        isModalOpen:false
    },
    reducers:{
        showFilterModal:(state)=>{
            state.isModalOpen=true
        },
        hideFilterModal:(state)=>{
            state.isModalOpen=false
        }
    }
})

export const {showFilterModal,hideFilterModal}=filterModal.actions
