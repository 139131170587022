import {createSlice} from '@reduxjs/toolkit'
export const  tableLoader =createSlice({
    name:"loader",
    initialState:{
        loader:false
    },
    reducers:{
        showLoader:(state)=>{
            state.loader=true
        },
        hideLoader:(state)=>{
            state.loader=false
        }
    }
});
export const {showLoader,hideLoader}=tableLoader.actions;